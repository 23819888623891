import React from "react";
import ComposedPlots from "./RechartsMultipleLinesScatter";

function polynomialRegression(points, degree) {
  var n = points.length;
  var lhs = []; // Left-hand side matrix (normal equations)
  var rhs = []; // Right-hand side vector

// Initialize lhs and rhs
for (let row = 0; row <= degree; row++) {
  lhs[row] = [];
  for (let col = 0; col <= degree; col++) {
      lhs[row][col] = 0;
  }
  rhs[row] = 0;
}

// Compute sums for lhs and rhs
for (var i = 0; i < n; i++) {
  var xi = points[i].x;
  var yi = points[i].y;
  for (let row = 0; row <= degree; row++) {
      for (let col = 0; col <= degree; col++) {
          lhs[row][col] += Math.pow(xi, row + col);
      }
      rhs[row] += yi * Math.pow(xi, row);
  }
}

// Solve the system lhs * coeffs = rhs
var coeffs = gaussianElimination(lhs, rhs);
return coeffs;
}

function gaussianElimination(A, B) {
  var n = A.length;
  for (let i = 0; i < n; i++) {
      // Find the pivot row
      var maxEl = Math.abs(A[i][i]);
      var maxRow = i;
      for (var k = i + 1; k < n; k++) {
          if (Math.abs(A[k][i]) > maxEl) {
              maxEl = Math.abs(A[k][i]);
              maxRow = k;
          }
      }

  // Swap maximum row with current row
  for (let k = i; k < n; k++) {
      let tmp = A[maxRow][k];
      A[maxRow][k] = A[i][k];
      A[i][k] = tmp;
  }
  let tmp = B[maxRow];
  B[maxRow] = B[i];
  B[i] = tmp;

  // Make all rows below this one 0 in current column
  for (let k = i + 1; k < n; k++) {
      let c = -A[k][i] / A[i][i];
      for (var j = i; j < n; j++) {
          if (i === j) {
              A[k][j] = 0;
          } else {
              A[k][j] += c * A[i][j];
          }
      }
      B[k] += c * B[i];
  }
}

// Solve equation Ax=b for an upper triangular matrix A
let x = new Array(n);
for (let i = n - 1; i >= 0; i--) {
  x[i] = B[i];
  for (let j = i + 1; j < n; j++) {
      x[i] -= A[i][j] * x[j];
  }
  x[i] /= A[i][i];
}
return x;
}


export default function RechartSingleLinePlot(Props)
{
  const MainPlot= Props.Datas
  const XScaleType = Props.XScaleType
  const Averageline = Props.Averageline
  const PlotName = Props.Legend?Props.Legend:'Data'
  const TrendCurve=[]
  const Datas=[MainPlot]

  
  if (Averageline )
  {
    let now = new Date()
    let XOffset =  new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0)).getTime()/1000;
    let XPoints = MainPlot.Points.map(P=>{ return {x:(P.x-XOffset)/3600/24, y:P.y}})
    let Coefs=polynomialRegression(XPoints,2)
    console.log("coefs",Coefs)

    MainPlot.Points.forEach(element => 
      {
        let v=0
        let xPow=1
        if (element)
        {
          let X = (element.x-XOffset)/24/3600
          for (let i=0;i< Coefs.length; i++)
          {
            v+=xPow*Coefs[i]
            xPow *= X
          }
          TrendCurve.push({x:element.x,y:v})
        }
      });
    MainPlot.LegendName=PlotName
    if (TrendCurve)
    {
      let LegendName=''
      let Value =0
      let Exps = ['','X','X²']
      let PrevSign =''
      for (let i=0;i< Coefs.length; i++)
      {
        if (Coefs[i]>1e-2)
        {
          Value = "+"+Coefs[i].toFixed(2)
        }
        else if (Coefs[i]>0)
        {
          Value = "+"+Coefs[i].toExponential(2)
        }
        else if (Coefs[i]<-1e-2)
        {
          Value = ""+Coefs[i].toFixed(2)
        }
        else
        {
          Value = ""+Coefs[i].toExponential(2)
        }
        
        let CoefLegend=''
        if (i<Exps.length)
        {
          CoefLegend=Value+Exps[i]
        }
        else
        {
          CoefLegend=Value+"x^"+i
        }
        LegendName=CoefLegend+' '+PrevSign+LegendName

        if (Coefs>0)
        {
          PrevSign="+"
        }
        else
        {
          PrevSign=""
        }
          
      }
      console.log("Trend Curve",LegendName)
      Datas.push({Points:TrendCurve,Line:'#00FF00', FillValue:'#F000FF00', Dot:null, LegendName:"Trend "+LegendName})
    }
  }

  
  if (MainPlot)
  {
    return <ComposedPlots Datas={Datas} XScaleType={XScaleType} />
  }
  else
  {
    return <></>
  }
}